<template>
  <div :class="$style.container">
    <div :class="$style.notes">
      <NoteCard
        v-for="note in notes"
        :key="note?.id"
        :parentId="note?.id"
        :dealerId="note?.dealers?.[0]?.id"
        :name="note?.managerAccount?.name"
        :role="note?.managerAccount?.role"
        :image="note?.managerAccount?.image"
        :created-at="note?.createdAt"
        :note-message="note?.message"
        :comments="note?.comments"
        :class="$style.card"
        @new-note="createNote"
        @delete="deleteNote"
        @edit="editNote"
      ></NoteCard>
    </div>
    <Pagination
      v-if="pageCount > 1"
      @go-next="goNext()"
      @go-prev="goPrev()"
      :curPage="page"
      :lastPage="pageCount"
    />
  </div>
</template>

<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'

import NoteCard from '@/components/atoms/cards/NoteCard.vue'
import Pagination from '@/components/atoms/Paginations.vue'

export default {
  components: { Pagination, NoteCard },
  mixins: [notifications],
  created() {
    this.getNotesList()
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.limit)
    },
  },
  data() {
    return {
      notes: [],
      page: 1,
      total: 0,
      limit: 20,
    }
  },
  methods: {
    async createNote({ parentId, newNote, dealerId }) {
      const { error } =
        await delivery.AddwineCore.DealersActions.createDealerNote(dealerId, {
          parentId,
          message: newNote,
        })

      if (error)
        return this.showNotification('Ошибка создания заметки', 'error')
      this.showNotification('Заметка была успешно добавлена', 'success')
      await this.getNotesList()
    },
    async editNote({ parentId, noteId, message }) {
      const { error } = await delivery.AddwineCore.NotesActions.update(noteId, {
        parentId,
        message,
      })

      if (error)
        return this.showNotification('Ошибка редактирования заметки', 'error')
      this.showNotification('Заметка была успешно изменена', 'success')
      await this.getNotesList()
    },
    async deleteNote({ id }) {
      const { error } = await delivery.AddwineCore.NotesActions.delete(id)

      if (error)
        return this.showNotification('Ошибка удаления заметки', 'error')
      this.showNotification('Заметка была успешно удалена', 'success')
      await this.getNotesList()
    },
    async goNext() {
      if (this.page < this.pageCount) {
        this.page += 1
        await this.getNotesList()
      }
    },
    async goPrev() {
      if (this.page > 1) {
        this.page -= 1
        await this.getNotesList()
      }
    },
    async getNotesList() {
      const { value, error } = await delivery.AddwineCore.NotesActions.getList({
        page: this.page,
        limit: this.limit,
        preloadDealers: true,
      })

      if (error) return

      this.notes = value.data
      this.total = value.meta.count
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 1rem;

  .notes {
    display: grid;
    gap: 1rem;
    .card {
      max-width: 100%;
      @include modalShadow;
    }
  }
}
</style>
